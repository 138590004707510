<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>装修监理服务列表</span>
			</div>
			<el-tabs v-model="tabIndex" @tab-click="handleClick">
				<el-tab-pane v-for="(tab,tabI) in tabbars" :key="tabI" :label="tab.name">
					<operate-search @search="search" @superSearch="superSearch" @clear="clear" placeholder="请输入客户姓名">
						<template #operateButtons>
							<el-button type="primary" size="small" @click="showForm">添加服务</el-button>
						</template>
						<template #searchMore>
							<el-form ref="searchForm" :inline="true" :model="searchData" label-width="80px">
								<el-form-item class="mt-1 mb-0" label="客户姓名">
									<el-input size="small" v-model="searchData.name" placeholder="请输入客户姓名"
										clearable></el-input>
								</el-form-item>
								<el-form-item class="mt-1 mb-0" label="联系方式">
									<el-input size="small" v-model="searchData.mobile" placeholder="请输入联系方式"
										clearable></el-input>
								</el-form-item>
								<el-form-item class="mt-1 mb-0" label="楼盘">
									<el-input size="small" v-model="searchData.estate" placeholder="请输入楼盘"
										clearable></el-input>
								</el-form-item>
								<el-form-item class="mt-1 mb-0" label="户型">
									<el-input size="small" v-model="searchData.house_type" placeholder="请输入户型"
										clearable></el-input>
								</el-form-item>
								<el-form-item class="mt-1 mb-0" label="收货地址">
									<el-input size="small" v-model="searchData.address" placeholder="请输入收货地址"
										clearable></el-input>
								</el-form-item>
							</el-form>
						</template>
					</operate-search>
					<el-table ref="multipleTable" :data="dataList" tooltip-effect="dark" style="width: 100%">
						<el-table-column prop="name" label="客户姓名">
						</el-table-column>
						<el-table-column prop="mobile" label="联系方式">
						</el-table-column>
						<el-table-column prop="mobile" label="楼盘房号">
							<template slot-scope="scope">
								<div>
									{{scope.row.estate}} {{scope.row.building}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="mobile" label="验房师">
							<template slot-scope="scope">
								<div v-if="scope.row.admin">
									{{scope.row.admin.name}}
								</div>
								<div v-else class="color-danger">
									未分配
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="status_text" label="服务状态">
							<template slot-scope="scope">
								<el-tag size="small" type="danger" v-if="scope.row.status == 0">{{scope.row.status_text}}</el-tag>
								<el-tag size="small" type="warning" v-if="scope.row.status == 1">{{scope.row.status_text}}</el-tag>
								<el-tag size="small" type="success" v-if="scope.row.status == 2">{{scope.row.status_text}}</el-tag>
								<el-tag size="small" type="info" v-if="scope.row.status == 3">{{scope.row.status_text}}</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="create_time" label="创建时间" width="100">
						</el-table-column>
						<el-table-column prop="operate" label="操作">
							<template slot-scope="scope">
								<el-button type="text" size="mini" @click="editService(scope.row)">编辑</el-button>
								<el-button type="text" size="mini" class="color-danger" @click="deleteItem(scope.row)" v-if="roleId == 1">
									删除</el-button>
								<el-popover placement="bottom" width="150" trigger="click" class="pl-2"
											v-if="scope.row.status != 0 || scope.row.report_pdf != ''">
									<div class="d-flex justify-content-center text-center" style="flex-direction: column;width: 100%;">
										<div v-if="roleId == 1 && scope.row.status != 0 && scope.row.status != 3">
											<el-button type="text" size="mini" @click="showAdminForm(scope.row)">分配服务</el-button>
										</div>
										<div v-if="scope.row.status == 2">
											<el-button type="text" size="mini" @click="completeService(scope.row)">服务完成</el-button>
										</div>
										<div v-if="scope.row.status == 3">
											<el-button type="text" size="mini" @click="createPDF(scope.row)">{{scope.row.report_pdf?'重新生成':'生成报告'}}</el-button>
										</div>
										<div v-if="scope.row.report_pdf">
											<el-button type="text" size="mini" @click="readPDF(scope.row)">查看报告</el-button>
										</div>
										<div v-if="scope.row.status != 0 && scope.row.status != 3">
											<el-button type="text" size="mini" @click="cancelService(scope.row)">取消服务</el-button>
										</div>
									</div>
									<el-button type="text" size="mini" class="text-secondary" slot="reference">更多</el-button>
								</el-popover>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
			<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange"
				:current-page="pageData.current" :page-sizes="pageData.sizes" :page-size="pageData.size"
				layout="total, sizes, prev, pager, next, jumper" :total="pageData.total" class="mt-3">
			</el-pagination>
		</el-card>
		<!-- 添加服务 -->
		<el-dialog :title="formTitle" :visible.sync="formVisible">
			<el-form :model="formData" :rules="serviceRules" ref="serviceRuleForm">
				<el-form-item label="客户姓名" :label-width="formLabelWidth" prop="name">
					<el-input v-model="formData.name" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="联系方式" :label-width="formLabelWidth" prop="mobile">
					<el-input v-model="formData.mobile" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="楼盘" :label-width="formLabelWidth" prop="estate">
					<el-input v-model="formData.estate" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="房号" :label-width="formLabelWidth" prop="building">
					<el-input v-model="formData.building" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="户型" :label-width="formLabelWidth" prop="house_type">
					<el-input v-model="formData.house_type" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="面积" :label-width="formLabelWidth" prop="area">
					<el-input v-model="formData.area" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
				<el-form-item label="收货地址" :label-width="formLabelWidth" prop="address">
					<el-input v-model="formData.address" autocomplete="off" size="small" style="width: 80%;">
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="formVisible = false">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</div>
		</el-dialog>
		
		<!-- 分配监理 -->
		<el-dialog title="分配监理" :visible.sync="adminFormVisible">
		  <el-form :model="bindForm">
		    <el-form-item label="监理" label-width="120px">
		      <el-select v-model="bindForm.admin_id" placeholder="请选择监理">
		        <el-option v-for="(item,index) in adminList" :label="item.name" :value="item.id"></el-option>
		      </el-select>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="adminFormVisible = false">取 消</el-button>
		    <el-button type="primary" @click="bindAdmin">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import operateSearch from "@/components/common/operate-search.vue"
	import common from "@/common/mixins/common.js"
	export default {
		components: {
			operateSearch
		},
		inject: ['layout'],
		mixins: [common],
		data() {
			return {
				preUrl: 'service',
				tabIndex: 0,
				tabbars: [{
					name: '全部',
					status_id:''
				}],
				roleId:0,
				dataList: [],
				formTitle: '添加服务',
				formVisible: false,
				formData: {},
				formLabelWidth: '120px',
				service_id:'',
				adminList: [],
				bindForm:{
					admin_id:''
				},
				adminFormVisible:false,
				searchData: {
					name: '',
					mobile: '',
					estate: '',
					building: '',
					house_type: '',
					address:'',
					status: ''
				},
				serviceRules: {
					name: [{
						required: true,
						message: '请输入客户姓名',
						trigger: 'blur'
					}, ],
					mobile: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
					estate: [{
						required: true,
						message: '请输入楼盘',
						trigger: 'blur'
					}],
					building: [{
						required: true,
						message: '请输入房号',
						trigger: 'blur'
					}],
					house_type: [{
						required: true,
						message: '请输入户型',
						trigger: 'blur'
					}],
					area: [{
						required: true,
						message: '请输入面积',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入收货地址',
						trigger: 'blur'
					}]
				}
			};
		},
		watch:{
			formVisible:function(){
				if(!this.formVisible){
					this.$refs['serviceRuleForm'].resetFields();
				}
			},
			adminFormVisible:function(){
				if(!this.adminFormVisible){
					this.$refs['bindForm'].resetFields();
					this.service_id = ''
				}
			}
		},
		methods: {
			getListRes(e) {
				this.dataList = e.list
				this.adminList = e.admin
				this.tabbars = e.tab_list
				this.roleId = e.role_id
			},
			getUrl() {
				let url = `/admin/${this.preUrl}/index/${this.pageData.current}?limit=${this.pageData.size}&type=3&status=${this.tabbars[this.tabIndex].status_id}`
				for (let key in this.searchData) {
					if(this.searchData[key]) {
						url += '&' + key + '=' + this.searchData[key]
					}
				}
				return url
			},
			showForm() {
				this.formData = {
					name: '',
					mobile: '',
					estate: '',
					building: '',
					house_type: '',
					area: '',
					address:'',
					status: 1
				}
				this.formVisible = true
			},
			submit() {
				this.$refs['serviceRuleForm'].validate((valid) => {
					if (valid) {
						let id = 0
						this.addOrEdit(this.formData, id)
						this.formVisible = false
					}
				})
			},
			handleClick(tab, event) {
				console.log(tab, event);
				this.tabIndex = tab.index
				this.getList()
				
			},
			search(e) {
				this.searchData.name = e
				this.getList()
			},
			superSearch(e) {
				console.log(this.searchData)
				this.getList()
			},
			clear(e) {
				this.searchData = {
					name: '',
					mobile: '',
					estate: '',
					building: '',
					house_type: '',
					address:'',
					status: ''
				}
				this.getList()
			},
			editService(data) {
				this.$router.push({
					name: 'service_supervision_edit',
					params: {
						serviceId: data.id
					}
				})
			},
			showAdminForm(row) {
				this.service_id = row.id
				this.adminFormVisible = true
			},
			bindAdmin() {
				let data = {
					admin_id:this.bindForm.admin_id
				}
				let url = '/admin/service/bindadmin/'+this.service_id
				this.axios.post(url,data,{token:true})
				.then((res)=>{
					console.log(res)
					this.adminFormVisible = false
					this.$message({
						message: '分配成功',
						type: 'success'
					});
					this.getList()
				})
				.catch((err) => {
					console.log(err)
				})
			},
			completeService(data){
				this.$confirm('请确保服务结果已完整录入！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'info'
				}).then(() => {
					this.layout.loading = true
					this.axios.post(`/admin/${this.preUrl}/updatestatus/${data.id}`, {status:3}, {
							token: true
						})
						.then((res) => {
							this.$message({
								type: 'success',
								message: '服务完成!'
							});
							this.getList()
						})
						.catch((err) => {
							this.layout.loading = false
						})
				}).catch(() => {
					
				});
			},
			cancelService(data) {
				this.$confirm('服务取消后部分操作将无法进行', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'info'
				}).then(() => {
					this.layout.loading = true
					this.axios.post(`/admin/${this.preUrl}/updatestatus/${data.id}`, {status:0}, {
							token: true
						})
						.then((res) => {
							this.$message({
								type: 'success',
								message: '服务已取消'
							});
							this.getList()
						})
						.catch((err) => {
							this.layout.loading = false
						})
				}).catch(() => {
					
				});
			},
			createPDF(row){
				this.$confirm('请确保服务结果已完整录入', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'info'
				}).then(() => {
					this.layout.loading = true
					this.axios.post(`/admin/${this.preUrl}/createpdf/${row.id}`, {}, {
							token: true
						})
						.then((res) => {
							this.$message({
								type: 'success',
								message: '报告已生成'
							});
							this.getList()
						})
						.catch((err) => {
							this.layout.loading = false
						})
				}).catch(() => {
					
				});
			},
			readPDF(row){
				let url = this.$conf.serverUrl+'pdf/'+ row.report_pdf
				window.open(url, '_blank')
			}
		}
	};
</script>

<style>
</style>
